export default class Galleries {
  constructor() {
    const $slide = $('.project-carousel').slick({
      adaptiveHeight: true,
      arrows: true,
      dots: true,
      customPaging: (slick, index) => {
        let targetImage = slick.$slides.eq(index).find('img').attr('src') || '';
        return `<div class="active-border"></div>
          <div class="thumbnail" style="background:url(${targetImage})"></div>`;
      }
    });

    $slide.find('.slick-slide').on('click', () => {
      $slide.slick('slickNext');
    })

    window.initCultureSliders = this.initCultureSliders;
    this.initCultureSliders();
  }

  initCultureSliders() {
    $('.post__media--carousel:not(.slick-initialized)').slick({
      dots: false
    });
  }
}