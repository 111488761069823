export default class Hero {
  constructor() {
    if ($('.down-arrow').length > 0) {
      this.wrapperChildren = $('#wrapper').children('.hero-promo, #content');
      this.wrapperChildrenLength = this.wrapperChildren.length;

      this.addViewportClasses();

      $(window).on('scroll', () => {
        this.addViewportClasses();
      });

      $('.down-arrow').on('click', () => {
        this.slideToNextHero();
      });
    }
  }

  addViewportClasses() {
    const viewportRect = document.body.getBoundingClientRect();

    this.wrapperChildren.each((index, child) => {
      const childTop = $(child).offset().top,
        viewportBottom = -viewportRect.top + 200;

      if (index < this.wrapperChildrenLength - 1 && childTop > viewportBottom) {
        $(child).addClass('below');
      } else if (index === this.wrapperChildrenLength - 1 && childTop > viewportBottom + viewportRect.height - 300) {
        $(child).addClass('below');
      } else {
        $(child).removeClass('below');
      }
    });

    if (!this.wrapperChildren.hasClass('below')) {
      $('.down-arrow').fadeOut(200);
    } else {
      $('.down-arrow').fadeIn(200);
    }
  }

  slideToNextHero() {
    if (this.wrapperChildren.not('.below').last().length) {
      const currentHero = this.wrapperChildren.not('.below').last(),
        nextHeroTop = currentHero.next().offset().top;

      $('html, body').stop().animate({ scrollTop: nextHeroTop }, 600, 'easeOutQuad');
    } else {
      const currentHero = this.wrapperChildren.last().offset().top

      $('html, body').stop().animate({ scrollTop: currentHero }, 600, 'easeOutQuad');
    }
  }
}